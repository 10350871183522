import { Button, Input, Space } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';

export const getColumnSearchProps = (dataIndex, searchInput, setSearchText, setSearchedColumn ,setPdfChildData,pdfChildData, childData) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn ,setPdfChildData,pdfChildData)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters, setSearchText,setPdfChildData,childData)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) => {
    const recordValue = record[dataIndex];
    return recordValue ? recordValue.toString().toLowerCase().includes(value.toLowerCase()) : false;
  },
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current.select(), 100);
    }
  },
});

export const getFilteredData = (data, searchText, searchedColumn) => {
  if (!searchText || !searchedColumn) return data;
  return data.filter((item) => {
    const recordValue = item[searchedColumn];
    return recordValue ? recordValue.toString().toLowerCase().includes(searchText.toLowerCase()) : false;
  });
};

const handleSearch = (selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn ,setPdfChildData ,pdfChildData) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);

  const filteredData = selectedKeys[0]? pdfChildData.filter((item) =>
    item[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase()) 
  ):pdfChildData;
  
  setPdfChildData(filteredData)
};

const handleReset = (clearFilters, setSearchText ,setPdfChildData,childData) => {
  clearFilters();
  setSearchText('');
  setPdfChildData(childData)
};