import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


import ProductList from '../component/Product/ProductList';
import ProductHistoryList from '../component/Product/ProductHistoryList';
import { segment_option } from '../component/Product/common/segmen_options';
import { AuthContext } from '../helpers/AuthContex';

import { Button, Segmented, Modal, Spin } from 'antd';
import { TagOutlined,PrinterOutlined } from '@ant-design/icons';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ProductHistoryDocument from '../component/Product/pdf/pdf_product_history';
import ProductListDocument from '../component/Product/pdf/pdf_product_list';
import { pdf } from '@react-pdf/renderer';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import axios from 'axios';

const ProductPage = () => {
  const navigate = useNavigate();

  const { authState } = useContext(AuthContext);
  const [segmentState, setSegmentState] = useState('List');

  const [currentDepartment, setCurrentDepartment] = useState('ทั้งหมด');

  const [childData, setChildData] = useState(null);
  const [pdfChildData, setPdfChildData] = useState(null);

  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false)
  const [pdfDataUrl, setPdfDataUrl] = useState('blob:http://localhost:3000/2fa78e5f-a484-4359-97b4-a72405780a25');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/patient/clinic/byId/${authState.department_id}`,
          { headers: { accessToken: Cookies.get('accessToken') } }
        );
        setCurrentDepartment(response.data.department); // Assuming the response contains departmentName
      } catch (error) {
        console.error('Error fetching department:', error);
      }
    };

    if (authState.department_id !== 0) {
      fetchDepartment();
    } else {
      setCurrentDepartment('ทั้งหมด');
    }
  }, [authState.department_id]);

  const handleDataFromChild = (fetchedData) => {
    setChildData(fetchedData);
    setPdfChildData(fetchedData);
  };

  const renderSegmentContent = {
    List: () => <ProductList user={authState}   onDataFetched={handleDataFromChild} setLoading = {setLoading} setPdfChildData={setPdfChildData} pdfChildData={pdfChildData} childData={childData}/>,
    History: () => <ProductHistoryList user={authState} onDataFetched={handleDataFromChild} setLoading = {setLoading} setPdfChildData={setPdfChildData} pdfChildData={pdfChildData}  childData={childData}/>,
  };

  const renderPdfContent = {
    List: () => <ProductListDocument data={pdfChildData} d_label={currentDepartment}/>,
    History: () => <ProductHistoryDocument data={pdfChildData} d_label={currentDepartment}/>,
  };

  const handleModalOpen = async () =>
    {
      console.log(authState)
      setIsPdfModalOpen(true);
      setLoading(true);
        try {
          const DocumentComponent = renderPdfContent[segmentState]?.();
    
          const blob = await pdf(DocumentComponent).toBlob();

          const url = URL.createObjectURL(blob);
          setPdfDataUrl(url.toString())
        } catch (error) {
          console.error('Error generating PDF:', error);
        } finally {
          setLoading(false);
        }

    }

    const handleModalCancle = () =>
      {
        setIsPdfModalOpen(false)
      }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={loading}>
            <CircularProgress color="inherit" />
      </Backdrop>
      <div className='flex justify-center items-center mt-2 p-2'>
        <TagOutlined className='md:text-[75px] text-[1rem] text-[#000000]' />
        <h5 className='text-[3rem] !mb-[0]'>รายการโปรดักส์</h5>
      </div>
      <div style={{ paddingTop: '10px' }}>
        <div className='md:p-[24px] md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl'>
          <div className='md:px-[24px] px-[0.5rem] pt-[24px] bg-white shadow-2xl rounded-xl pb-[24px]'>
            <Segmented
              options={segment_option}
              value={segmentState}
              onChange={setSegmentState}
            />
            <PrinterOutlined className='p-1 border-[1px] border-grey rounded-md cursor-pointer' onClick={handleModalOpen} />
            {renderSegmentContent[segmentState]?.() || (
              <div>Content not found</div>
            )}
          </div>
        </div>
      </div>
      {authState.role === 'admin' && (
        <Button className='bg-white m-5' onClick={() => { navigate('/admin/service') }}>แก้ไขสินค้า</Button>
      )}

      <Modal width={1000} title="ออกบิล" open={isPdfModalOpen} onCancel={handleModalCancle} footer={[<Button key="ok" >ตกลง</Button>,<Button key="close" onClick={handleModalCancle} >ปิด</Button>]}>
          <embed src={pdfDataUrl} frameBorder={"0"} width={"100%"} height={window.innerWidth < 786 ? "500px" : "1000px"}/>
      </Modal>

    </>
  );
};

export default ProductPage;
