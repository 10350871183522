import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import THSarabunIT from '../../../asset/fonts/THSarabunNew.ttf';

import {
    Table,
    TableHeader,
    TableCell,
    TableBody,
    DataTableCell,
  } from "@david.kucsai/react-pdf-table";
  
Font.register({ family: 'THSarabunIT', src: THSarabunIT });


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        fontFamily: 'THSarabunIT',
        padding: 10,
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
    },
});

const ProductHistoryDocument = ({ data, d_label }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.contentContainer}>
                    <Text style={styles.title}>สรุปยอดการขายรายการโปรดักส์ สาขา: {d_label}</Text>
                </View>
                <View>
                    <Table data={data}>
                            <TableHeader>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey',fontSize:'11' }}>
                                    รหัสการขาย 
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey' ,fontSize:'11' }}>
                                    รหัสโปรดักส์  
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey' ,fontSize:'11' }}>
                                    ชื่อโปรดักส์ 
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey' ,fontSize:'11' }}>
                                    วันที่ทำรายการ 
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey',fontSize:'11' }}>
                                    จำนวนการขาย 
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey',fontSize:'11' }}>
                                    <Text>
                                    ราคาขาย 
                                    </Text>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey',fontSize:'11' }}>
                                    <Text>
                                    จำนวนเงิน 
                                    </Text>
                                </TableCell>
                            </TableHeader>
                            <TableBody>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.id}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.product_id}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.Product.product_name}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.createdAt}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.quantity_sold}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.sale_price}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.total_price}/>
                            </TableBody>
                        </Table>
            </View>
    </Page>
  </Document>
  );
          };

export default ProductHistoryDocument;
