import { Button, Input, Space } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import { getColumnSearchProps } from './search_helpers';

const ProductListColumns = ({ onButtonClick ,setPdfChildData,pdfChildData, childData}) => {
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const columns = [
    {
      title: 'รหัสโปรดักส์',
      dataIndex: 'id',
      key: 'id',
      width: '8%',
      ...getColumnSearchProps('id', searchInput, setSearchText, setSearchedColumn, setPdfChildData,pdfChildData, childData),
    },
    {
      title: 'ชื่อโปรดักส์',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'สาขา',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '8%',
      ...getColumnSearchProps('department_name', searchInput, setSearchText, setSearchedColumn, setPdfChildData,pdfChildData, childData),
    },
    {
      title: 'จำนวนโปรดักส์',
      dataIndex: 'product_quantity',
      key: 'product_quantity',
      width: '8%',
    },
    {
      title: 'จัดการ',
      dataIndex: 'operation',
      key: 'operation',
      width: '8%',
      render: (text, record) => (
        <Button onClick={() => onButtonClick(record)}>จัดการโปรดักส์</Button>
      ),
    },
  ];

  return columns;
};

export default ProductListColumns;