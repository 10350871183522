import React, { useRef, useState } from 'react';
import { getColumnSearchProps } from './search_helpers';

const ProductHistoryListColumns = ({setPdfChildData,pdfChildData, childData}) => {
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const columns = [
    {
      title: 'รหัสการขาย',
      dataIndex: 'id',
      key: 'id',
      width: "5%",
      align: "center",
      ...getColumnSearchProps('id', searchInput, setSearchText, setSearchedColumn,setPdfChildData,pdfChildData, childData),
    },
    {
      title: 'รหัสโปรดักส์',
      dataIndex: 'product_id',
      key: 'product_id',
      width: "5%",
      align: "center",
      ...getColumnSearchProps('product_id', searchInput, setSearchText, setSearchedColumn,setPdfChildData,pdfChildData, childData),
    },
    {
      title: 'ชื่อโปรดักส์',
      dataIndex: ['Product', 'product_name'],
      key: 'product_name',
    },
    {
      title: 'วันที่ทำรายการ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: "center",
      width: "15%",
      ...getColumnSearchProps('createdAt', searchInput, setSearchText, setSearchedColumn,setPdfChildData,pdfChildData, childData),
    },
    {
      title: 'จำนวนการขาย',
      dataIndex: 'quantity_sold',
      key: 'quantity_sold',
      width: "10%",
      align: "center",
    },
    {
      title: 'ราคาขาย',
      dataIndex: 'sale_price',
      key: 'sale_price',
      width: "10%",
      align: "center",
      render: (value) => `${value.toFixed(2)}`, // Format as currency
    },
    {
      title: 'จำนวนเงิน',
      dataIndex: 'total_price',
      key: 'total_price',
      width: "10%",
      align: "center",
      render: (value) => `${value.toFixed(2)}`,
    },
  ];

  return columns;
};

export default ProductHistoryListColumns;