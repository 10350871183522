import React, { useEffect,  useState, useContext,useRef} from 'react';
import { Button, Input, Space, Table, Radio ,Modal} from 'antd'
import axios from 'axios';
import Cookies from 'js-cookie';
import {useNavigate } from 'react-router-dom';
import { AuthContext } from '../helpers/AuthContex';

import './PatientList.css'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SearchOutlined , PrinterOutlined} from '@ant-design/icons';
import {css} from '@emotion/css'
import moment from 'moment';

import PatientDocument from '../pages/pdf_patient';
import { pdf } from '@react-pdf/renderer';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';





const PatientListcom = () => {
  const {authState} = useContext(AuthContext)
  const [patient, setListOfPatients] = useState([]);
  const [initPatient ,setListOfInitPatient] = useState();
  const [isLoading, setLoading] = useState(true);
  const [dataReady, setDataReady] = useState(false); // Add this state variable

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [radioSelected, setRadioSelected] = useState('ลูกค้าทั้งหมด');

  const [tempPatient, setTempPatient] = useState([])
  const [todayPatient,setTodayPatient] = useState([]);
  const [isModalOpen, setIsModalopen] = useState(false)
  const [pdfDataUrl, setPdfDataUrl] = useState('blob:http://localhost:3000/2fa78e5f-a484-4359-97b4-a72405780a25');
  const [departmentLabel, setDepartmentLabel] = useState()

  const navi = useNavigate()
  useEffect(() => {
    setLoading(true);
    axios.get(process.env.REACT_APP_API_URL+`/patient/bydp/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},})
      .then((response) => {
        setListOfPatients(response.data);
        setListOfInitPatient(response.data);
        setTempPatient(response.data);
        axios.get(process.env.REACT_APP_API_URL+'/patient/clinic',{ headers:{
          accessToken: Cookies.get('accessToken'),
        }}).then((dresponse)=>{  
          const label = dresponse.data.find(item=> item.id === authState.department_id)?.department
          setDepartmentLabel(label? label: 'ทั้งหมด')
          axios.get(process.env.REACT_APP_API_URL + `/course/bill`,{ headers:{accessToken: Cookies.get('accessToken'),}}).then((billresponse)=>{
            const today = moment().format('YYYY-MM-DD'); 
            const filteredBills = billresponse.data?.filter(item => moment(item.createdAt, 'MM/DD/YYYY, HH:mm:ss').format('YYYY-MM-DD') === today);
            const todayPatient = response.data.filter((item)=> filteredBills.some(bill=> parseInt(bill.patient_id) === item.id))
            setTodayPatient(todayPatient);
          })
        })
      })
      .finally(() => {
        setLoading(false);
        setDataReady(true); // Set dataReady to true when data fetching is complete
      });
      
  }, []);

  useEffect(() => {
    if (radioSelected === 'ลูกค้าที่ใช้บริการวันนี้') {
      setListOfInitPatient(todayPatient);
      setTempPatient(todayPatient);
    }
  }, [todayPatient, radioSelected]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const filteredData = selectedKeys[0]? initPatient.filter((item) =>
      item[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase()) 
    ):initPatient;
    
    setTempPatient(filteredData)
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`ค้าหา ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className='bg-[#7877FF]'
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });


  const handleRadioSelect = (value) =>
  {
    setRadioSelected(value)
    if (value === 'ลูกค้าใหม่วันนี้') {
      const today = moment().format('YYYY-MM-DD'); 
      const filteredData = patient?.filter(item => moment(item.createdAt, 'MM/DD/YYYY, HH:mm:ss').format('YYYY-MM-DD') === today);
      setListOfInitPatient(filteredData);
      setTempPatient(filteredData)
    }
    else if(value === "ลูกค้าที่ใช้บริการวันนี้" ){
      setListOfInitPatient(todayPatient)
      setTempPatient(todayPatient)
    }else{
      setListOfInitPatient(patient)
      setTempPatient(patient)
    }
  }


   function handleRowClick(row) {
    navi(`/patient/${row.id}/${authState.department_id}`)
  }

  const handleModalOpen = async () =>
    {
      setIsModalopen(true);
        try {
          const blob = await pdf(
            <PatientDocument data={tempPatient} d_label={departmentLabel}/>
        ).toBlob({ quality: 1 });

          const url = URL.createObjectURL(blob);
          setPdfDataUrl(url.toString())
        } catch (error) {
          console.error('Error generating PDF:', error);
        }

    }
  const handleModalCancle = () =>
  {
    setIsModalopen(false)
  }

  const columns = [
    {
      title: 'รหัสคนไข้',
      dataIndex: 'id',
      key: 'id',
      width:'10%',
      ...getColumnSearchProps('id'),
    },
    {
        title: 'ชื่อคนไข้',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
    },
    {
    title: 'อายุ',
    dataIndex: 'age',
    key: 'age',
    width:'5%',
    },
    {
    title: 'เบอร์โทรศัพท์',
    dataIndex: 'call_data',
    key: 'call_data',
    width:'15%',
    ...getColumnSearchProps('call_data'),
    },
    {
      title: 'เลขบัตรประชาชน',
      dataIndex: 'idd',
      key: 'idd',
      width:'15%',
      ...getColumnSearchProps('idd'),
      },
    {
      title: 'วันที่เข้าร่วม',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width:'15%',
      ...getColumnSearchProps('createdAt'),
      
      },
    {
      title: 'วันเกิด',
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      width:'15%',
      ...getColumnSearchProps('date_of_birth'),
      },
      
    
  ];
  

if (isLoading){
    return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
    <CircularProgress color="inherit" />
  </Backdrop>
   ;
}

const tableCSS = css({
        backgroundColor: 'white',
        '& table': {
          borderCollapse: 'collapse'
        },
        '& thead > tr': {
          borderWidth: '1px',
          borderColor: 'black',
          borderStyle: 'solid',
          backgroundColor: "blue"
        },
        '.ant-table-thead .ant-table-cell':
        {
          backgroundColor: '#00BA9E',
          color: 'white',
          borderRadius: 'unset !important'
        },
        '& .ant-table-tbody > tr > td:hover': {
          cursor: 'pointer'
        }
      });

  return (
    <div className='md:p-[24px]  bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[1rem] rounded-xl' >
      <div className={`md:px-[24px] px-[1rem] pt-[24px] bg-white md:shadow-2xl rounded-xl ${(patient.length === 0 ) ? 'pb-[24px]' : null}`}>
        <div className='flex justify-between mx-10 py-2' >
        <Radio.Group value={radioSelected} onChange={(e) => {handleRadioSelect(e.target.value)}}>
          <Radio value="ลูกค้าทั้งหมด">ลูกค้าทั้งหมด</Radio>
          <Radio value="ลูกค้าใหม่วันนี้">ลูกค้าใหม่วันนี้</Radio>
          <Radio value="ลูกค้าที่ใช้บริการวันนี้">ลูกค้าที่ใช้บริการวันนี้</Radio>
        </Radio.Group>
        <PrinterOutlined className='p-1 border-[1px] border-grey rounded-md cursor-pointer' onClick={handleModalOpen}/>
        </div>
          <Table
          columns={columns}
          dataSource={initPatient}
          rowKey="id" 
          onRow={(record, rowIndex) => {
              return {
              onClick: event => {handleRowClick(record)}, 
          }}}
          className={tableCSS}
          size={"small"}
          scroll={{x:'max-content'}}
          />
        </div>
        <Modal width={1000} title="ออกบิล" open={isModalOpen} onCancel={handleModalCancle} footer={[<Button key="ok" >ตกลง</Button>,<Button key="close" onClick={handleModalCancle} >ปิด</Button>]}>
          <embed src={pdfDataUrl} frameBorder={"0"} width={"100%"} height={window.innerWidth < 786 ? "500px" : "1000px"}/>
        </Modal>
      </div>
  )
}

export default PatientListcom