import React, { useState, useEffect } from "react";
import { Modal, InputNumber, Button } from "antd";

const ProductManageModal = ({ open, onClose, onSave, selectedProduct, isAdmin }) => {
  const [product, setProduct] = useState(selectedProduct);
  const [initialQuantity, setInitialQuantity] = useState(selectedProduct?.product_quantity || 0);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useEffect(() => {
    setProduct(selectedProduct);
    setInitialQuantity(selectedProduct?.product_quantity || 0);
  }, [selectedProduct]);

  useEffect(() => {
    if (!isAdmin && product?.product_quantity < initialQuantity) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [product?.product_quantity, initialQuantity, isAdmin]);

  const handleQuantityChange = (action) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      product_quantity:
        action === "increment"
          ? prevProduct.product_quantity + 1
          : Math.max(0, prevProduct.product_quantity - 1),
    }));
  };

  const handleInputChange = (value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      product_quantity: value,
    }));
  };

  const handleSave = () => {
    onSave(product);
  };

  return (
    <Modal
      title="การจัดการคลังโปรดักส์"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} className="mr-2">
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          className="bg-blue-500"
          onClick={handleSave}
          disabled={isSaveDisabled} // Disable save button if validation fails
        >
          Save
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-4">
        <div>
          <strong>ชื่อโปรดักส์:</strong> {product?.product_name}
        </div>
        <div className="flex items-center gap-2">
          <Button
            type="text"
            onClick={() => isAdmin && handleQuantityChange("decrement")}
            className="text-lg"
            disabled={!isAdmin} // Disable the button for non-admins
          >
            -
          </Button>
          <InputNumber
            min={0}
            value={product?.product_quantity}
            onChange={handleInputChange}
            className="w-20"
          />
          <Button
            type="text"
            onClick={() => handleQuantityChange("increment")}
            className="text-lg"
          >
            +
          </Button>
        </div>
        {!isAdmin && isSaveDisabled && (
          <p className="text-red-500">User ธรรมดาไม่สามารถลดจำนวนคลังโปรดักส์ได้</p>
        )}
      </div>
    </Modal>
  );
};

export default ProductManageModal;
