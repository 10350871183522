import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import { Table, Spin } from 'antd';

import product_history_list_columns from './common/product_history_list_column';
import tableDecoration from '../../utils/TableDecoration';

const ProductHistoryList = ({ user , onDataFetched ,setLoading ,setPdfChildData ,pdfChildData , childData}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/course/product-sold-transaction/${user.department_id}`,
        { headers: { accessToken: Cookies.get('accessToken') } }
      );
      setProducts(data);
      onDataFetched(data)
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <Table
        dataSource={products}
        className={tableDecoration}
        columns={product_history_list_columns({setPdfChildData: setPdfChildData,pdfChildData: pdfChildData ,childData: childData})}
        size={'small'}
      />
    </>
  );
};

export default ProductHistoryList;