import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Table, Spin } from 'antd';

import ProductManageModal from '../Product/ProductManageDialog';
import product_list_columns from './common/product_list_column';
import tableDecoration from '../../utils/TableDecoration';

const ProductList = ({ user, onDataFetched ,setLoading , setPdfChildData,pdfChildData , childData}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({ product_name: '', product_quantity: 0 });

  const toggleModal = (product = {}) => {
    setSelectedProduct(product);
    setIsModalOpen(!isModalOpen);
  };

  const saveProduct = async (data) => {
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/course/product/quantity-update`,
        data,
        { headers: { accessToken: Cookies.get('accessToken') } }
      );
      fetchProducts();
      toggleModal();
    } catch (error) {
      console.error('Error updating product quantity:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/course/product/${user.department_id}`,
        { headers: { accessToken: Cookies.get('accessToken') } }
      );
      console.log(data)
      setProducts(data);
      onDataFetched(data)
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <Table
        dataSource={products}
        columns={product_list_columns( {onButtonClick:toggleModal , setPdfChildData:setPdfChildData , pdfChildData:pdfChildData, childData:childData})}
        className={tableDecoration}
        size="small"
        rowKey={(record) => record.id || record.key}
      />
      <ProductManageModal
        open={isModalOpen}
        onClose={() => toggleModal()}
        onSave={saveProduct}
        selectedProduct={selectedProduct}
        isAdmin={user.role === 'admin'}
      />
    </>
  );
};

export default ProductList;