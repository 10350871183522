import { css } from '@emotion/css';

const tableDecoration = css({
  backgroundColor: 'white',
  '& table': {
    borderCollapse: 'collapse',
  },
  '& thead > tr': {
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: 'blue',
  },
  '& .ant-table-thead .ant-table-cell': {
    backgroundColor: '#00BA9E',
    color: 'white',
    borderRadius: 'unset !important',
  },
});

export default tableDecoration;
