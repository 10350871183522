import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import Angsana from '../asset/fonts/ANGSA.ttf';
import moment from 'moment';
import {
    Table,
    TableHeader,
    TableCell,
    TableBody,
    DataTableCell,
  } from "@david.kucsai/react-pdf-table";
import { grey } from '@mui/material/colors';
  
Font.register({ family: 'Angsana', src: Angsana });


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        fontFamily: 'Angsana',
        padding: 10,
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
    },
});

const formatDate=(date)=>
{
   return moment(date, 'MM/DD/YYYY, HH:mm:ss').format('DD/MM/YYYY') || 0
}

const PatientDocument = ({ data, d_label }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.contentContainer}>
                    <Text style={styles.title}>สรุปรายชื่อคนไข้ สาขา: {d_label}</Text>
                </View>
                <View>
                    <Table data={data}>
                            <TableHeader>
                                <TableCell  weighting={0.1} style={{ textAlign: 'center' ,backgroundColor:'grey' }}>
                                    รหัสคนไข้
                                </TableCell>
                                <TableCell weighting={0.30}   style={{ textAlign: 'center',backgroundColor:'grey' }}>
                                    ชื่อ-สกุลคนไข้
                                </TableCell>
                                <TableCell weighting={0.05} style={{ textAlign: 'center',backgroundColor:'grey' }}>
                                    อายุ
                                </TableCell>
                                <TableCell weighting={0.1} style={{ textAlign: 'center' ,backgroundColor:'grey'}}>
                                    เบอร์โทรศัพท์
                                </TableCell>
                                <TableCell weighting={0.15} style={{ textAlign: 'center' ,backgroundColor:'grey'}}>
                                    เลขบัตรประชาชน
                                </TableCell>
                                <TableCell weighting={0.10}style={{ textAlign: 'center',backgroundColor:'grey' }}>
                                    วันเกิด
                                </TableCell>
                                <TableCell weighting={0.1}style={{ textAlign: 'center',backgroundColor:'grey' }}>
                                    หมายเหตุ
                                </TableCell>
                                <TableCell weighting={0.1} style={{ textAlign: 'center',backgroundColor:'grey' }}>
                                    PT Dx.
                                </TableCell>
                            </TableHeader>
                            <TableBody>
                                <DataTableCell weighting={0.1} style={{ textAlign: 'center' }} getContent={(r) => r.id}/>
                                <DataTableCell weighting={0.30} style={{ textAlign: 'center' }} getContent={(r) => r.name}/>
                                <DataTableCell weighting={0.05} style={{ textAlign: 'center' }} getContent={(r) => r.age}/>
                                <DataTableCell weighting={0.1} style={{ textAlign: 'center' }} getContent={(r) => r.call_data}/>
                                <DataTableCell weighting={0.15} style={{ textAlign: 'center' }} getContent={(r) => r.idd}/>
                                <DataTableCell weighting={0.10}  style={{ textAlign: 'center' }} getContent={(r) => r.date_of_birth}/>
                                <DataTableCell weighting={0.1} style={{ textAlign: 'center' }} getContent={(r) => {}}/>
                                <DataTableCell weighting={0.1} style={{ textAlign: 'center' }} getContent={(r) => r.ptdx}/>
                            </TableBody>
                        </Table>
            </View>
    </Page>
  </Document>
  );
          };

export default PatientDocument;
