import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';

export const segment_option = [
    {
      label: 'รายการ',
      value: 'List',
      icon: <BarsOutlined />,
    },
    {
      label: 'ประวัติการซื้อ-ขาย',
      value: 'History',
      icon: <AppstoreOutlined />,
}]
